import axios from 'axios';
// import { Navigate } from 'react-router-dom';


export const ajaxUrl = '/api/';
const fetch = axios.create({
  baseURL: ajaxUrl,
  timeout: 300000,
});


fetch.interceptors.request.use((config: any) => {
  // const token = mstorage.getSStorage("token");
  // if (token) {
  //   config.headers = { Authorization: `Bearer ${token}` };
  // } else {
  //   config.headers = {};
  // }
  return config;
});

fetch.interceptors.response.use(
  (data) => data,
  (error) => {
    if (error && error.response) {
      const { status, data } = error.response;
      if (status === 500 && data) {
        const { code } = data;
        switch (code) {
          case -1101: //User Not Login
          case -1113: //Account Expired
          case -1114: //Authentication Credentials Not Found
            break;
          default:
        }
      } else if (status > 0) {
      }
    }
    return error;
  }
);


export default fetch;