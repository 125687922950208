import React from "react";
import {
  BrowserRouter,
  NavigateOptions,
  To,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import { ConfigProvider, ErrorBlock } from "antd-mobile";
import zhCN from "antd-mobile/es/locales/zh-CN";
import My from "./pages/auth/My";
import Client from "./pages/auth/Main";
import Bookin from "./pages/auth/Bookin";
import Behavior from "./pages/msginfo/Behavior";
import Iosch from "./pages/msginfo/Iosch";
import Index from "./pages/Index";

const App = () => {
  const Main = () => {
    const AppRouter = [
      {
        path: "/*",
        children: [
          {
            path: "auth/*",
            element: <Client />,
            children: [
              { path: "bookin", element: <Bookin /> },
              { path: "my", element: <My /> },
              {
                path: "*",
                element: (
                  <ErrorBlock
                    fullPage
                    title="对不起，您没有访问该页面的权限，请与管理员联系！"
                  />
                ),
              },
            ],
          },
          {
            path: "msginfo/*",
            children: [
              { path: "behavior/:sysid/:recordId", element: <Behavior /> },
              { path: "iosch/:sysid/:idtId", element: <Iosch /> },
            ],
          },
          { path: "warninfo", element: <>aaabc</> },

          {
            path: "login",
            //   element: <Login title={title} copyright={copyright} />,
          },

          { path: "", element: <Index /> },
          {
            path: "*",
            element: (
              <ErrorBlock
                status="empty"
                fullPage
                title="对不起，您访问的页面不存在！"
              />
            ),
          },
        ],
      },
    ];
    return useRoutes(AppRouter);
  };
  return (
    <ConfigProvider locale={zhCN}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
