import fetch from "@/utils/fetch";
import { Button, Form, Input, Toast } from "antd-mobile";
import { useEffect, useState, useRef } from "react";
import { useOutletContext } from "react-router-dom";

const EditPhone = (props: any) => {
  const { openid } = useOutletContext<{ openid: string }>();
  const { callbackfunc } = props;
  const [form] = Form.useForm();
  const [endtime, setEndtime] = useState<number>(0);
  const [phoneno, setPhoneno] = useState<string>();
  useEffect(() => {
    document.title = "绑定手机号";
  }, []);

  const onFinish = async (values: any) => {
    const res = await fetch.post(
      `/weixin/user/validcode/${openid}`,
      {},
      {
        params: values,
      }
    );
    if (res?.status === 200) {
      Toast.show({ content: "验证成功" });
      callbackfunc && callbackfunc();
    }
  };

  const sendSms = () => {
    if (!phoneno || phoneno.length !== 11) {
      Toast.show({ content: "请先输入有效手机号" });
      return;
    }
    setEndtime(120);
    fetch.get(`/weixin/user/validcode/${openid}`, { params: { phoneno } });
    const ref = setInterval(() => {
      setEndtime((per) => {
        if (per === 1) {
          clearInterval(ref);
        }
        return --per;
      });
    }, 1000);
  };

  return (
    <Form
      layout="horizontal"
      mode="card"
      onFinish={(values: any) => {
        onFinish(values);
      }}
      onValuesChange={(changedValues, allValues) => {
        if ("phoneno" in changedValues) {
          setPhoneno(changedValues?.phoneno);
        }
      }}
      footer={
        <Button block type="submit" color="primary" size="large">
          提交
        </Button>
      }
    >
      <Form.Item name="phoneno" label="手机号" rules={[{ required: true }]}>
        <Input placeholder="请输入11位手机号" maxLength={11} />
      </Form.Item>
      <Form.Item
        name="code"
        label="短信验证码"
        rules={[{ required: true }]}
        extra={
          (endtime <= 0 && <a onClick={sendSms}>发送验证码</a>) || (
            <a
              style={{ color: "var(--adm-color-text-secondary)" }}
              aria-disabled
            >
              {endtime}秒后重试
            </a>
          )
        }
      >
        <Input placeholder="请输入" />
      </Form.Item>
    </Form>
  );
};

export default EditPhone;
