import diagest from "@/utils/diagest";
import fetch, { ajaxUrl } from "@/utils/fetch";
import { Image, Skeleton, Space } from "antd-mobile";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import videojs from "video.js";
import "video.js/dist/video-js.css";

const Behavior = (props: any) => {
  const { sysid, recordId } = useParams();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
  document.title = "行为告警信息";
  useEffect(() => {
    if (sysid && recordId) {
      setLoading(true);
      fetch
        .post(`/weixin/behavior/${sysid}/${recordId}`)
        .then((res: any) => {
          if (res?.status === 200) {
            const data = res?.data?.data;
            setData(data);
            setLoading(false);
            downloadVideo(data?.data?.realVideoPath);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [sysid, recordId]);

  const downloadVideo = async (realVideoPath: string) => {
    if (realVideoPath) {
      const response = await fetch.get(
        `/weixin/behavior/video/${sysid}/${recordId}/${diagest.desencode(
          "file",
          realVideoPath
        )}`,
        { responseType: "blob" }
      );
      if (response.status === 200) {
        const type =
          response.headers["context-type"] && "application/octet-stream";
        let filename: any = response.headers["content-disposition"];
        const pos = filename.indexOf("filename=");
        if (pos > 0) {
          filename = filename.substring(pos + 9);
          if (filename.indexOf('"') > -1) {
            filename = filename.substring(1, filename.length - 1);
          }
        }
        const blob = new Blob([response.data], { type: "audio/mp4" });
        const url = window.URL.createObjectURL(blob);
        setVideoUrl(url);
      }
    } else {
      setVideoUrl(undefined);
    }
  };
  return (
    <Space direction="vertical">
      {(loading && (
        <>
          <Skeleton
            animated
            style={{ height: "200px", width: "100vw" }}
          ></Skeleton>
          <div style={{ height: "5px", width: "98vw" }} />
          <Skeleton
            animated
            style={{ height: "200px", width: "100vw" }}
          ></Skeleton>
        </>
      )) || (
        <Image src={`data:image/Jpeg;base64,${data?.data.fullPicture}`}></Image>
      )}
      <div style={{ height: "5px", width: "98vw" }} />
      {data?.data?.realVideoPath &&
        ((videoUrl && (
          <video autoPlay style={{ width: "100%", height: "100%" }} controls>
            <source src={videoUrl}></source>
            您的手机浏览器不支持该格式
          </video>
        )) || (
          <Skeleton
            animated
            style={{ height: "200px", width: "100vw" }}
          ></Skeleton>
        ))}
    </Space>
  );
};

export default Behavior;
