import { Outlet } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import QueryString from "qs";
import { useEffect, useState } from "react";
import fetch from "@/utils/fetch";
const Main = () => {
  const { search } = useLocation();
  const [openid, setOpenId] = useState<string>();

  useEffect(() => {
    //为什么会两次刷新？
    const params = QueryString.parse(search.substring(1)); //去除开头的？
    if (params?.code) {
      fetch.get(`/weixin/user/code/${params?.code}`, {}).then((res) => {
        if (res?.status === 200) {
          setOpenId(res.data?.data);
        }
      });
    }
  }, [search]);

  return <Outlet context={{ openid }} />;
};
export default Main;
