import diagest from "@/utils/diagest";
import fetch, { ajaxUrl } from "@/utils/fetch";
import { Form, Image, Skeleton, Space } from "antd-mobile";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./index.css";

const Iosch = (props: any) => {
  const { sysid, idtId } = useParams();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  document.title = "进出校信息";
  useEffect(() => {
    if (sysid && idtId) {
      setLoading(true);
      fetch
        .post(`/weixin/iosch/${sysid}/${idtId}`)
        .then((res: any) => {
          if (res?.status === 200) {
            const data = res?.data?.data;
            setData(data);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [sysid, idtId]);

  return (
    <div style={{ width: "100%" }}>
      <div className="iosch-header">
        学生{(data?.inoutType == 0 && "进") || "出"}校信息
      </div>
      {(loading && <Skeleton.Paragraph lineCount={8} animated />) || (
        <>
          <div className="iosch-item">
            <div className="iosch-title">编号</div>
            <div className="iosch-text">{data?.personNo}</div>
          </div>
          <div className="iosch-item">
            <div className="iosch-title">姓名</div>
            <div className="iosch-text">{data?.personName}</div>
          </div>
          <div className="iosch-item">
            <div className="iosch-title">
              {(data?.inoutType == 0 && "进") || "出"}校时间
            </div>
            <div className="iosch-text">{data?.identificationTime}</div>
          </div>
          <div className="iosch-item">
            <div className="iosch-title">抓拍照</div>
            <div className="iosch-text">
              <Image
                src={data?.snapimg}
                width={122}
                height={122}
                fit="contain"
              />
            </div>
          </div>
          <div className="iosch-item">
            <div className="iosch-title">识别照</div>
            <div className="iosch-text">
              <Image
                src={data?.faceimg}
                width={122}
                height={122}
                fit="contain"
              />
            </div>
          </div>
          <div className="iosch-item">
            <div className="iosch-title">体温</div>
            <div className="iosch-text">
              {(data?.temperature && `${data?.temperature}°C`) || "未测温"}
            </div>
          </div>
          <div className="iosch-item">
            <div className="iosch-title">对比结果</div>
            <div className="iosch-text">
              {(data?.recognizeFlag === "false" && "失败") || "成功"}
            </div>
          </div>
          <div className="iosch-item">
            <div className="iosch-title">身份证</div>
            <div className="iosch-text">{data?.cardNo}</div>
          </div>
          <div className="iosch-item">
            <div className="iosch-title">设备</div>
            <div className="iosch-text">{data?.deviceName}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default Iosch;
