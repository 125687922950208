import fetch from "@/utils/fetch";
import {
  Button,
  CascadePicker,
  CascadePickerOption,
  CascadePickerRef,
  Cascader,
  Checkbox,
  DatePicker,
  DatePickerRef,
  Ellipsis,
  Form,
  ImageUploader,
  Input,
  Modal,
  Space,
  Tag,
  TextArea,
  Toast,
} from "antd-mobile";
import dayjs from "dayjs";
import update from "immutability-helper";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { Cropper } from "react-cropper";
import headpng from "./head.png";
import plus from "./plus.png";
import "./index.css";
import "~/cropperjs/dist/cropper.css";

const Bookin = () => {
  const [options, setOptions] = useState<CascadePickerOption[]>([]);
  const [schoollist, setSchoolList] = useState<CascadePickerOption[]>([]);
  const [formDisable, setFormDisable] = useState<boolean>(false);
  const [imgVisible, setImgVisible] = useState(false);
  const [imgsrc, setImgSrc] = useState<any>("");
  const [filelist, setFilelist] = useState<any>([]);
  const [form] = Form.useForm();
  const cropperRef = useRef<any>(null);
  const saveImg = () => {
    const img = cropperRef?.current.cropper.getCroppedCanvas().toDataURL();

    form.setFieldValue("bookinAvatar", img);
    setImgVisible(false);
  };
  useEffect(() => {
    document.title = "预约进校";
    fetch.post("/weixin/district/list", {}).then((res) => {
      if (res?.status === 200) {
        const data = res.data?.data;
        const getChildren = (fid: number) => {
          return data
            .filter((o: any) => o.fid === fid)
            ?.map((o: any) => {
              const re: any = { label: o.districtName, value: o.id };
              const cdata = getChildren(o.id);
              if (cdata && cdata.length > 0) {
                re["children"] = cdata;
              }
              return re;
            });
        };
        const ddata = data
          .filter((o: any) => "province" === o.districtLevel)
          .map((o: any) => {
            const re: any = { label: o.districtName, value: o.id };
            const cdata = getChildren(o.id);
            if (cdata && cdata.length > 0) {
              re["children"] = cdata;
            }
            return re;
          });
        setOptions((info: any) => update(info || [], { $set: ddata }));
      }
    });
  }, []);

  const onFinish = (values: any) => {
    const { school, bookinBooktime, bookinLasttime, hasRead, ...vals } = values;
    if (!values.bookinAvatar) {
      return;
    }
    if (!hasRead) {
      Toast.show("请勾选我已阅读");
      return;
    }

    fetch
      .post("/weixin/bookin", {
        ...vals,
        bookinBooktime: dayjs(bookinBooktime).format("YYYY-MM-DD HH:mm"),
        bookinLasttime: dayjs(bookinLasttime).format("YYYY-MM-DD HH:mm"),
        bookinSchool: school?.[0],
      })
      .then((res) => {
        if (res.status === 200) {
          Toast.show({ content: "预约成功" });
          setFormDisable(true);
        }
      });
  };

  const onSelectImg = (file: File, files: any) => {
    // if (file.size > 300 * 1024) {
    //   Toast.show("头像最大不允许超过300k");
    //   return null;
    // }
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      const dataurl: any = reader.result;
      img.src = dataurl;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d") as CanvasRenderingContext2D;
        const iw = parseFloat((600.0 / img.width).toFixed(2));
        const ih = parseFloat((600.0 / img.height).toFixed(2));
        const ii = Math.min(iw, ih);

        canvas.width = img.width * ii;
        canvas.height = img.height * ii;
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(
          img,
          0,
          0,
          img.width,
          img.height,
          0,
          0,
          canvas.width,
          canvas.height
        );

        setImgSrc(canvas.toDataURL());
        setImgVisible(true);
      };
    };
    reader.readAsDataURL(file);
    return null;
  };

  const avater = Form.useWatch("bookinAvatar", form);

  const renderLabel = useCallback((type: string, data: number) => {
    switch (type) {
      case "year":
        return data + "年";
      case "month":
        return data + "月";
      case "day":
        return data + "日";
      case "hour":
        return data + "时";
      case "minute":
        return data + "分";
      case "second":
        return data + "秒";
      default:
        return data;
    }
  }, []);
  return (
    <div className="bookin">
      <div className="bihead">
        <img src={headpng} />
      </div>
      <div className="biform">
        <Form
          mode="card"
          form={form}
          disabled={formDisable}
          onFinish={onFinish}
          onValuesChange={(changedValues: any) => {
            if ("bookinArea" in changedValues) {
              fetch
                .get(`/weixin/cmp/S/${changedValues.bookinArea[2]}`)
                .then((res) => {
                  if (res?.status === 200) {
                    const data = res.data?.data;
                    setSchoolList(
                      data.map((o: any) => ({
                        label: o.mpcmpName,
                        value: o.id,
                      }))
                    );
                  }
                });
            }
          }}
          // layout="horizontal"
          footer={
            <Button
              disabled={formDisable}
              block
              type="submit"
              color="primary"
              size="large"
            >
              提交
            </Button>
          }
        >
          <Form.Header />
          <Form.Item name="bookinAvatar" hidden>
            <Input />
          </Form.Item>

          <Form.Item
            label="地区"
            rules={[{ required: true }]}
            name="bookinArea"
            trigger="onConfirm"
            onClick={(e, pickerRef: RefObject<CascadePickerRef>) => {
              pickerRef.current?.open();
            }}
          >
            <CascadePicker
              options={options}
              // value={value}
              // onConfirm={v => {
              //   setValue(v)
              // }}
            >
              {(items) => {
                if (items.every((item) => item === null)) {
                  return "";
                } else {
                  return items.map((item) => item?.label ?? "未选择").join("-");
                }
              }}
            </CascadePicker>
          </Form.Item>
          <Form.Item
            label="学校"
            name="school"
            rules={[{ required: true }]}
            trigger="onConfirm"
            onClick={(e, pickerRef: RefObject<CascadePickerRef>) => {
              pickerRef.current?.open();
            }}
          >
            <CascadePicker
              options={schoollist}
              // value={value}
              // onConfirm={v => {
              //   setValue(v)
              // }}
            >
              {(items) => {
                if (items.every((item) => item === null)) {
                  return "";
                } else {
                  return items.map((item) => item?.label ?? "未选择").join("-");
                }
              }}
            </CascadePicker>
          </Form.Item>
          <Form.Item
            label="接待老师"
            name="bookinTeacher"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入接待老师姓名" maxLength={20} />
          </Form.Item>
          <Form.Item
            label="手机号码"
            name="bookinTphone"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入接待老师手机号码" maxLength={11} />
          </Form.Item>
          <Form.Header />
          <Form.Item
            label="预计进校时间"
            name="bookinBooktime"
            required
            trigger="onConfirm"
            onClick={(e, pickerRef: RefObject<DatePickerRef>) => {
              pickerRef.current?.open();
            }}
          >
            <DatePicker precision="minute" renderLabel={renderLabel}>
              {(value) =>
                value ? dayjs(value).format("YYYY-MM-DD HH:mm") : "请选择时间"
              }
            </DatePicker>
          </Form.Item>
          <Form.Item
            label="预计出校时间"
            name="bookinLasttime"
            required
            trigger="onConfirm"
            onClick={(e, pickerRef: RefObject<DatePickerRef>) => {
              pickerRef.current?.open();
            }}
          >
            <DatePicker precision="minute" renderLabel={renderLabel}>
              {(value) =>
                value ? dayjs(value).format("YYYY-MM-DD HH:mm") : "请选择时间"
              }
            </DatePicker>
          </Form.Item>

          <Form.Item
            label="来访单位"
            name="bookinCompany"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="请输入来访单位，没有单位则填写无"
              maxLength={30}
            />
          </Form.Item>

          <Form.Item
            label="来访者姓名"
            name="bookinName"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入来访者姓名" maxLength={20} />
          </Form.Item>

          <Form.Item
            label="身份证号码"
            name="bookinCardno"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入有效身份证号码" maxLength={18} />
          </Form.Item>
          <Form.Item
            label="手机号"
            name="bookinPhone"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入11位手机号码" maxLength={11} />
          </Form.Item>
          <Form.Item label="车牌号" name="bookinCarnum">
            <Input placeholder="如开车进校，请输入车牌号" maxLength={15} />
          </Form.Item>
          <Form.Item
            label={
              <>
                来访原因
                <span className="adm-form-item-required-asterisk">*</span>
              </>
            }
          >
            <Space style={{ marginBottom: ".2rem" }} wrap>
              <Tag
                round
                style={{ cursor: "pointer", fontSize: "14px" }}
                color="#bfbfbf"
                onClick={() => {
                  form.setFieldValue("bookinInreason", "老师约家长入校");
                }}
              >
                老师约家长进校
              </Tag>
              <Tag
                round
                style={{ cursor: "pointer", fontSize: "14px" }}
                color="#bfbfbf"
                onClick={() => {
                  form.setFieldValue("bookinInreason", "已约老师进校拜访");
                }}
              >
                已约老师进校拜访
              </Tag>
              <Tag
                round
                style={{ cursor: "pointer", fontSize: "14px" }}
                color="#bfbfbf"
                onClick={() => {
                  form.setFieldValue("bookinInreason", "合作单位进校参观");
                }}
              >
                合作单位进校参观
              </Tag>
              <Tag
                round
                style={{ cursor: "pointer", fontSize: "14px" }}
                color="#bfbfbf"
                onClick={() => {
                  form.setFieldValue("bookinInreason", "已约进校参观");
                }}
              >
                已约进校参观
              </Tag>
              <Tag
                round
                style={{ cursor: "pointer", fontSize: "14px" }}
                color="#bfbfbf"
                onClick={() => {
                  form.setFieldValue("bookinInreason", "厂商进校检修设备");
                }}
              >
                厂商进校检修设备
              </Tag>
              <Tag
                round
                style={{ cursor: "pointer", fontSize: "14px" }}
                color="#bfbfbf"
                onClick={() => {
                  form.setFieldValue("bookinInreason", "其他");
                }}
              >
                其他
              </Tag>
            </Space>

            <Form.Item
              noStyle
              label="来访原因"
              name="bookinInreason"
              rules={[{ required: true }]}
            >
              <TextArea
                style={{
                  backgroundColor: "#eeeeee",
                }}
                placeholder="请输入来访原因"
                rows={2}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={
              <>
                来访者头像
                <span className="adm-form-item-required-asterisk">*</span>
              </>
            }
          >
            <ImageUploader
              beforeUpload={onSelectImg}
              // value={filelist}
              accept="image/*"
              style={{
                width: 122,
                height: 122,
                border: "1px dashed  #DCDEE3",
              }}
              upload={async (file: File) => {
                return {
                  url: URL.createObjectURL(file),
                };
              }}
              renderItem={() => (
                <img
                  style={{ width: 120, height: 120 }}
                  alt=""
                  src={form.getFieldValue("bookinAvatar")}
                />
              )}
              // capture="user"
              maxCount={1}
            >
              <img
                style={{ width: 120, height: 120 }}
                alt=""
                src={avater || plus}
              />
            </ImageUploader>
            <div className="adm-list-item-description">
              <div className="adm-form-item-feedback-error">
                请上传清晰的大头照，拍照时露出额头、耳朵，保证脸部清晰。
                <br />
                以证件照为佳，请勿上传艺术照、全身照。
              </div>
            </div>
          </Form.Item>
          <Form.Item>
            <div>
              <a>
                <h3>注意事项</h3>
              </a>
              1.稍后审核结果会发送到您的手机，请注意查收。
              <br />
              2.若预约进校成功，请携带身份证进校，进校时会进行身份证核验。
              <br />
              3.请在预约时间段内进出校园。
            </div>
          </Form.Item>
          <Form.Item name="hasRead" valuePropName="checked">
            <Checkbox>
              我已阅读
              <a>注意事项</a>
            </Checkbox>
          </Form.Item>
        </Form>
        <Modal
          visible={imgVisible}
          onClose={() => setImgVisible(false)}
          actions={[
            {
              key: "confirm",
              text: "确定",
              onClick: () => {
                saveImg();
              },
            },
          ]}
          content={
            <Cropper
              // viewMode={1}
              ref={cropperRef}
              src={imgsrc}
              style={{ height: 200, width: 200 }}
              aspectRatio={1}
            />
          }
        />
      </div>
    </div>
  );
};

export default Bookin;
