import { Input, List, NavBar, Picker, Space } from "antd-mobile";
import { useEffect, useState } from "react";
import update from "immutability-helper";
import "./index.css";
import { CheckOutline, EditSOutline } from "antd-mobile-icons";
import EditPhone from "./EditPhone";
import { useOutletContext } from "react-router-dom";
import fetch from "@/utils/fetch";

const EditNameInput = (props: any) => {
  const { value, onChange } = props;
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState<string | undefined>();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onSave = () => {
    setEditing(false);
    onChange(inputValue);
  };

  return (
    <>
      {!editing ? (
        <Space>
          {inputValue}
          <EditSOutline
            onClick={() => {
              setEditing(true);
            }}
          />
        </Space>
      ) : (
        <Space>
          <Input
            maxLength={50}
            value={inputValue}
            placeholder="请输入您的姓名"
            onChange={(v: string) => {
              setInputValue(v);
            }}
            onEnterPress={() => {
              onSave();
            }}
          ></Input>
          <CheckOutline
            color="var(--adm-color-primary)"
            onClick={() => {
              onSave();
            }}
          />
        </Space>
      )}
    </>
  );
};
const My = (props: any) => {
  const { openid } = useOutletContext<{ openid: string }>();
  const [sexVisible, setSexVisible] = useState(false);
  const [useinfo, setUserInfo] = useState<any>(null);
  const [bandingPhone, setBandingPhone] = useState(false);
  useEffect(() => {
    document.title = "个人资料";
  }, []);

  useEffect(() => {
    openid &&
      fetch.get(`/weixin/user/${openid}`).then((res) => {
        if (res?.status === 200) {
          const data = res?.data?.data;
          if (!data?.mpuserOpenid) {
            setBandingPhone(true);
          } else {
            setUserInfo({
              name: data?.mpuserName,
              sex: data?.mpuserSex,
              phone: data?.mpuserPhone,
            });
          }
        }
      });
  }, [openid]);

  const changeinfo = (key: string, value: any) => {
    setUserInfo((info: any) => update(info || {}, { [key]: { $set: value } }));
    fetch.patch(`/weixin/user/${openid}`, { [key]: value });
  };

  return !bandingPhone ? (
    <>
      <List mode="card" header={null}>
        {/* <List.Item onClick={() => {}}>头像</List.Item> */}
        <List.Item
          extra={
            <EditNameInput
              value={useinfo?.name}
              onChange={(value: string | undefined) => {
                changeinfo("name", value);
              }}
            />
          }
        >
          姓名
        </List.Item>
        <List.Item
          extra={useinfo?.sex === 1 ? "女" : "男"}
          onClick={() => {
            setSexVisible(true);
          }}
        >
          性别
        </List.Item>
        <List.Item
          extra={useinfo?.phone || "请绑定手机号"}
          onClick={() => {
            setBandingPhone(true);
          }}
        >
          手机号
        </List.Item>
      </List>
      <Picker
        visible={sexVisible}
        value={[useinfo?.sex]}
        onClose={() => {
          setSexVisible(false);
        }}
        onConfirm={(value) => {
          changeinfo("sex", value?.[0]);
        }}
        columns={[
          [
            { value: 0, label: "男" },
            { value: 1, label: "女" },
          ],
        ]}
      />
    </>
  ) : (
    <EditPhone
      callbackfunc={() => {
        setBandingPhone(false);
        fetch.get(`/weixin/user/${openid}`).then((res) => {
          if (res?.status === 200) {
            const data = res?.data?.data;
            if (!data?.mpuserOpenid) {
              setBandingPhone(true);
            } else {
              setUserInfo({
                name: data?.mpuserName,
                sex: data?.mpuserSex,
                phone: data?.mpuserPhone,
              });
            }
          }
        });
      }}
    />
  );
};

export default My;
